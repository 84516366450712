body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
	color: darkred;
}

.btn {
	background-color: darkred;
	color: white;
}
/* .jumbotron {
	background-color: #c66;
}

.audit {
	background-color:#c66;
}

.consulting {
	background-color: #c66;
}

.contact {
	background-color: #c66;
	color: pink;
	background-color: black;
	background-image: "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')" 
} */

.unsplash {
	max-height: 100%;
	max-width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
